@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@import url('https://fonts.googleapis.com/css2?family=Darumadrop+One&family=Frijole&?family=Gloria+Hallelujah&display&display=swap');

body{
  background-color: #d7cdcd;
  height: 100vh;
}
.app
{  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo{
  font-family: 'Frijole', cursive;
  font-size: 3em;
  color: black;
}

.name{
  font-family: 'Darumadrop One', sans-serif;
  font-size: 2em;
  color: black;
}

.counter
{
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 2em;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: blueviolet;
}